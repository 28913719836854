exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-areas-tsx": () => import("./../../../src/pages/Areas.tsx" /* webpackChunkName: "component---src-pages-areas-tsx" */),
  "component---src-pages-artigos-tsx": () => import("./../../../src/pages/Artigos.tsx" /* webpackChunkName: "component---src-pages-artigos-tsx" */),
  "component---src-pages-fale-tsx": () => import("./../../../src/pages/Fale.tsx" /* webpackChunkName: "component---src-pages-fale-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-institucional-tsx": () => import("./../../../src/pages/Institucional.tsx" /* webpackChunkName: "component---src-pages-institucional-tsx" */),
  "component---src-pages-teste-tsx": () => import("./../../../src/pages/teste.tsx" /* webpackChunkName: "component---src-pages-teste-tsx" */)
}

